(<template>
  <page-data-section :progress="progressActive"
                     class="employees">
    <div class="employees__content-wrapper">
      <div class="employees__filters-wrapper">
        <sk-input :placeholder="searchText"
                  :icon="'search'"
                  :preselected-value="nameSearch"
                  class="employees__name-search"
                  @fieldvaluechanged="catchNameSearch"
                  @enterpressed="applyFilters('filterEvent')" />
        <sk-select :items-list="departmentsFilterList"
                   :default-value="departmentFilterText"
                   :preselected-value="departmentId"
                   class="employee__department-filter"
                   @csvaluechanged="changeDepartment" />
        <div class="employees__btn-cont">
          <button class="sk-btn sk-btn--default employees__apply-filters-btn"
                  @click="applyFilters('filterEvent')">{{ $gettext('Apply filters') }}</button>
          <button v-if="canCreateEmployee"
                  class="sk-btn sk-btn--default employees__add-btn"
                  @click="openAddModal">{{ $gettext('Add employee') }}</button>
        </div>
      </div>
      <h3 class="employees__section-title">{{ $gettext('My profile') }}</h3>
      <div class="employees__col-names-wrapper">
        <p class="employees__col-name employees__col-name--employee">{{ $gettext('Employee') }}</p>
        <p class="employees__col-name employees__col-name--department">{{ $gettext('Department(s)') }}</p>
        <p class="employees__col-name">{{ $gettext('Admin') }}</p>
        <p class="employees__col-name">{{ $gettext('Manager') }}</p>
        <p class="employees__col-name">{{ $gettext('Coordinator') }}</p>
        <p class="employees__col-name employees__col-name--actions"></p>
      </div>
      <div class="employees__current-user-wrapper">
        <employee :user="currentEmployee"
                  :is-current-employee="true"
                  @changerights="changeRole"
                  @removemember="removeMember" />
      </div>
      <h3 class="employees__section-title">{{ $gettext('Employees') }}</h3>
      <div v-if="employees.length"
           class="employees__col-names-wrapper">
        <p class="employees__col-name employees__col-name--employee">{{ $gettext('Employee') }}</p>
        <p class="employees__col-name employees__col-name--department">{{ $gettext('Department(s)') }}</p>
        <p class="employees__col-name">{{ $gettext('Admin') }}</p>
        <p class="employees__col-name">{{ $gettext('Manager') }}</p>
        <p class="employees__col-name">{{ $gettext('Coordinator') }}</p>
        <!--        <p class="employees__col-name">{{ $gettext('Attender') }}</p>-->
        <p class="employees__col-name employees__col-name--actions"></p>
      </div>
      <div class="employees__users-wrapper">
        <employee v-for="(item, index) in employees"
                  :key="index"
                  :user="item"
                  @changerights="changeRole"
                  @removemember="removeMember" />
        <div class="employees__pagination-wrapper">
          <items-amount class="employees__items-per-page"
                        @amountchanged="applyFilters" />
          <sk-pagination :current="+page"
                         :total="+pages"
                         class="employees__pagination"
                         @prev="toPrevPage"
                         @next="toNextPage"
                         @newpage="toNewPage" />
        </div>
      </div>
    </div>
  </page-data-section>
</template>)

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import Employee from '@/components/enterprise/employees/Employee';
  import ItemsAmount from '@/components/shared_components/ItemsAmount';

  export default {
    asyncData({store, route}) {
      let promiseArr = '';
      const params = {
        page: route.query.page,
        amount: route.query.amount || store.state.filterItemsAmount || 10
      };
      // update current user data
      promiseArr = [...promiseArr, store.dispatch('EnterpriseStore/getCurrentEmployee')];

      if (!store.state.EnterpriseStore.enterpriseEmployees) {
        promiseArr = [...promiseArr, store.dispatch('EnterpriseStore/searchEmployees', params)];
      }
      if (!store.state.EnterpriseStore.departments) {
        promiseArr = [...promiseArr, store.dispatch('EnterpriseStore/getDepartments', {verbose: true})];
      }
      if (!store.state.EnterpriseStore.addresses) {
        promiseArr = [...promiseArr, store.dispatch('EnterpriseStore/getAddresses')];
      }
      return promiseArr ? Promise.all(promiseArr) : promiseArr;
    },
    components: {
      employee: Employee,
      'page-data-section': PageDataSection,
      'items-amount': ItemsAmount,
    },
    data() {
      return {
        nameSearch: this.$route.query.name || '',
        departmentId: this.$route.query.department || '',
        progressActive: false,
        currentPage: this.$route.query.page || 1,
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid', 'userIsAdminOfEnterprise', 'userIsFinanceManager']),
      ...mapState('EnterpriseStore', {
        employees: (state) => state.enterpriseEmployees || [],
        departments: (state) => state.departments || [],
        pages: ({employeesPages}) => employeesPages || 1,
        page: ({employeesPage}) => employeesPage || 1,
        currentEmployee: (state) => state.currentEmployee || {}
      }),
      canCreateEmployee() { return this.userIsAdminOfEnterprise || this.userIsFinanceManager; },
      searchText() { return this.$gettext('Search by name'); },
      departmentFilterText() { return this.$gettext('Filter by department'); },
      departmentsFilterList() {
        const departments = this.departments.map((item) => {
          return {id: item.id, name: item.name};
        });
        departments.unshift({id: '', name: this.$gettext('All')});
        return departments;
      },
      filterItems() { return this.$store.state.filterItemsAmount; }
    },
    watch: {
      $route(newVal) {
        if (newVal.query.department || newVal.query.name) {
          this.searchEmployees();
        } else {
          this.refetchData();
        }
      }
    },
    methods: {
      ...mapMutations('EnterpriseStore', ['removeEmployees']),
      refetchData() {
        // Do we need duplicate call of removeEmployees mutation?
        this.removeEmployees();
        this.$store.commit('EnterpriseStore/removeEmployees');
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      },
      applyFilters(filterEvent) {
        const queryParams = {
          page: this.currentPage,
          amount: this.filterItems
        };

        if (filterEvent === 'filterEvent') {
          queryParams.page = 1;
          this.currentPage = 1;
        }
        if (this.departmentId) queryParams.department = this.departmentId;
        if (this.nameSearch) queryParams.name = this.nameSearch;
        this.$router.push(this.$makeRoute({name: 'BuyerEmployees', query: queryParams}));
      },
      preselectDataAfterAddingDepartment() {
        this.$store.dispatch('fetchSelectList', 'departments');
      },
      openAddModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-employee',
          width: 410,
          data: {
            title: this.$gettext('New employee'),
            context: this,
            successHandlingCallbackName: 'refetchData'
          }
        });
      },
      changeDepartment(value) {
        this.departmentId = value;
        this.searchEmployees();
      },
      catchNameSearch(value) { this.nameSearch = value; },
      getSelectedEmployee(id) {
        return this.employees.find((user) => user.uid == id);
      },
      changeRole(value, name, id) {
        let resArr = [];
        const form = new FormData();
        const currentEmployee = this.getSelectedEmployee(id);
        if (currentEmployee) {
          resArr = [...currentEmployee.roles];
          if (value && resArr.indexOf(name) == -1) {
            resArr.push(name);
          } else if (!value && resArr.indexOf(name) != -1) {
            resArr.splice(resArr.indexOf(name), 1);
          }
          if (resArr.length) {
            for (const role of resArr) {
              form.append('employee[roles][]', role);
            }
          } else {
            form.append('employee[roles][]', '');
          }
          if (currentEmployee.departments && currentEmployee.departments.length) {
            for (const dep of currentEmployee.departments) {
              form.append('employee[departments][][id]', dep.id);
            }
          }
          if (currentEmployee.bookingReference) {
            form.append('employee[bookingReference]', currentEmployee.bookingReference);
          }
          if (currentEmployee.paymentBookingReference) {
            form.append('employee[paymentBookingReference]', currentEmployee.paymentBookingReference);
          }
          form.append('employee[email]', currentEmployee.email);
          this.$set(currentEmployee, 'roles', [...resArr]);
          if (id == this.userUid) {
            this.$store.commit('EnterpriseStore/setCurrentEmployeeRoles', [...resArr]);
          }
        }
        this.$store.dispatch('EnterpriseStore/updateEmployee', {form, id}).then(() => {
          if (id == this.userUid) {
            this.$store.commit('UserInfoStore/replaceEnterpriseRoles', resArr);
          }
          this.$store.commit('removeSelectsData', 'attenders');
        }).catch((error) => {
          if (error
            && error.data
            && error.data.errors
            && error.data.errors.admins_exists.includes('Last admin')) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              title: this.$gettext('Remove the administrator role'),
              text: this.$gettext('Sorry, you can\'t remove the administrator role from yourself. Please ask another administrator to remove your administrator role.')
            });
            resArr.indexOf(name) != -1 ? resArr.splice(resArr.indexOf(name), 1) : resArr.unshift(name);
            this.$set(currentEmployee, 'roles', [...resArr]);
            if (id == this.userUid) {
              this.$store.commit('EnterpriseStore/setCurrentEmployeeRoles', [...resArr]);
            }
          } else if (error?.status == 401) {
            Promise.all([this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid), this.$store.dispatch('EnterpriseStore/searchEmployees', {page: this.currentPage, amount: this.filterItems})]).then(() => {
              this.progressActive = false;
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Sorry, you don\'t have rights to manage employees. Please contact an administrator.')
              });
            });
          } else if (error?.status == 400) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('Sorry this action is not allowed. Please contact your administrator to change roles for you.')
            });
          } else {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }
        });
      },
      removeMember(id) {
        this.progressActive = true;
        this.$store.dispatch('EnterpriseStore/removeEmployee', id).then(() => {
          this.progressActive = false;
        }).catch((error) => {
          if (error?.status == 401) {
            Promise.all([this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid), this.$store.dispatch('EnterpriseStore/searchEmployees', {page: this.currentPage, amount: this.filterItems})]).then(() => {
              this.progressActive = false;
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Sorry you don\'t have rights to manage employees. Please contact an administrator.')
              });
            });
          } else if (error
            && error.data
            && error.data.errors
            && ((error.data.errors.uid
              && error.data.errors.uid.length
              && error.data.errors.uid.indexOf('You cannot kick this employee') !== -1)
              || (error.data.errors.uid
                && error.data.errors.uid.length
                && error.data.errors.uid.indexOf('There are attended jobs for') !== -1))) {
            this.progressActive = false;
            const titleText = this.$gettext('Delete enterprise account');
            const template = this.$gettext('Sorry, you can\'t delete yourself from this enterprise account. Please ensure there is at least one other employee, make them an administrator, and ask them to delete your account. If you wish to delete your enterprise account, please contact us at <a class="sk-link" href="mailto:%{support}">%{support}</a>');
            const infoMessage = this.$gettextInterpolate(template, {support: this.$getString('domainData', 'supportEmail')});
            this.$store.commit('InfoModalStore/setInfoModal', {text: infoMessage, title: titleText});
          } else if (error
            && error.data
            && error.data.errors
            && (error.data.errors.uid
              && error.data.errors.uid.length
              && error.data.errors.uid.indexOf('There are attended jobs for') !== -1)) {
            this.progressActive = false;
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('This user has active assignments or is an attender for them')
            });
          } else if (error
            && error.data
            && error.data.errors
            && error.data.errors.roles
            && error.data.errors.roles.admins_exists.includes('Last admin')) {
            this.progressActive = false;
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('There must be at least one administrator left on your account')
            });
          } else {
            this.progressActive = false;
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }
        });
      },
      searchEmployees() {
        const params = {
          page: this.currentPage,
          amount: this.filterItems
        };
        if (this.departmentId) params.department = this.departmentId;
        if (this.nameSearch) params.nameSearch = this.nameSearch;
        this.progressActive = true;
        this.$store.dispatch('EnterpriseStore/searchEmployees', params).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      },
      // --- Pagination --- //
      toPrevPage() {
        this.currentPage -= 1;
        this.applyFilters('pageNavigationEvent');
      },
      toNewPage(page) {
        this.currentPage = +page;
        this.$store.state.EnterpriseStore.page = +page;
        this.applyFilters('pageNavigationEvent');
      },
      toNextPage() {
        this.currentPage += 1;
        this.applyFilters('pageNavigationEvent');
      }
    },
    beforeMount() {
      if (this.$route.query.items) this.$store.commit('setFilterItemsAmount', this.$route.query.items);
      if (this.departmentId || this.nameSearch) {
        this.searchEmployees();
      }
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('EnterpriseStore/removeAddresses');
      this.removeEmployees();
      next();
    }
  };
</script>

<style>
  .employees .page-data-section__heading {
    padding: 20px 20px 0;
  }

  @media (max-width: 1024px) {
    .employees .page-data-section__heading {
      padding: 15px 15px 0;
    }
  }
</style>

<style scoped>
  .employees {
    padding: 0;
  }

  .employees__section-title {
    padding: 20px;
    color: #333;
    font-size: 18px;
    line-height: 20px;
  }

  .employees__content-wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
  }

  .employees__filters-wrapper {
    display: flex;
    width: 100%;
    padding: 20px 20px 0;
  }

  .employees__name-search {
    width: 100%;
    max-width: 250px;
    margin-right: 20px;
  }

  .employee__department-filter {
    flex-shrink: 0;
    width: 180px;
    margin-right: 20px;
  }

  .employees__col-names-wrapper {
    display: flex;
    width: 100%;
    padding: 0 20px;
  }

  .employees__col-name {
    position: relative;
    display: block;
    overflow: hidden;
    min-width: 75px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 10px;
    text-overflow: ellipsis;
  }

  .employees__col-name--employee {
    width: 100%;
  }

  .employees__col-name--department {
    width: 100%;
    max-width: 260px;
  }

  .employees__col-name--actions {
    min-width: 24px;
  }

  .employees__users-wrapper,
  .employees__current-user-wrapper {
    display: block;
    width: 100%;
  }

  .employees__current-user-wrapper {
    background-color: #fff4f0;
  }

  .employees__pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .employees__add-btn-wrapper {
    display: block;
    width: 100%;
    padding: 15px 7px 0;
  }

  .employees__btn-cont {
    display: flex;
    width: 100%;
  }

  .employees__apply-filters-btn {
    flex-shrink: 0;
    width: auto;
    padding: 0 10px;
  }

  .employees__add-btn {
    flex-shrink: 0;
    width: auto;
    margin-left: auto;
    padding: 0 10px;
  }

  @media (max-width: 1024px) {
    .employees__col-names-wrapper {
      display: none;
    }

    .employees__filters-wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 15px;
    }

    .employees__name-search {
      max-width: initial;
    }

    .employees__name-search,
    .employee__department-filter {
      order: 1;
      width: calc(50% - 15px);
      margin-right: 0;
    }

    .employees__apply-filters-btn {
      order: 1;
    }

    .employees__add-btn {
      margin: 0 auto 15px 0;
    }
  }

  @media (max-width: 767px) {
    .employees {
      padding-right: 0;
      padding-left: 0;
    }

    .employees__name-search,
    .employee__department-filter {
      width: 100%;
    }
  }
</style>
